'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.isSuppressEagerConnect = exports.setSuppressEagerConnection = void 0
const setSuppressEagerConnection = (value) => {
  if (value) {
    localStorage.setItem(NOT_EAGER_KEY, 'true')
  } else {
    localStorage.removeItem(NOT_EAGER_KEY)
  }
}
exports.setSuppressEagerConnection = setSuppressEagerConnection
const isSuppressEagerConnect = () => {
  var _a
  return !!((_a = global.localStorage) === null || _a === void 0
    ? void 0
    : _a.getItem(NOT_EAGER_KEY))
}
exports.isSuppressEagerConnect = isSuppressEagerConnect
const NOT_EAGER_KEY = 'suppress_eager_wallet_connect'
